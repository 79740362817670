import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {GET_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState} from '../types/state'
import {GetComponentDataParams, GetComponentDataResponse} from '../../shared-api'
import {updateNoEventsUrl} from './navigation'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_COMPONENT_DRAFT = createActions('UPDATE_COMPONENT_DRAFT')
export const UPDATE_COMPONENT = createActions('UPDATE_COMPONENT')

export const getComponentData = createAsyncAction<GetComponentDataResponse, GetComponentDataParams>(
  'GET_COMPONENT_DATA',
  async (params, {dispatch, extra: {serverApi}}) => {
    const component = await serverApi.getComponentData(params)
    dispatch({type: GET_SITE_SETTINGS.SUCCESS, payload: {settings: component.siteSettings}})

    if (params.viewMode !== 'editor') {
      dispatch(updateNoEventsUrl())
    }

    return component
  },
)

export const updateComponentSettings = (settings: object) => (dispatch: Function) => {
  dispatch({type: UPDATE_SETTINGS, payload: settings})
  return dispatch(updateNoEventsUrl())
}

export const updateComponentDraft = (component: wix.events.editor.WebComponentConfig) =>
  callAPI(UPDATE_COMPONENT_DRAFT, component)

export const updateComponent = () => (dispatch: Function, getState: GetState) => {
  dispatch(callAPI(UPDATE_COMPONENT, {settings: getState().component.settings}))
}
